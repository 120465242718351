import { Card } from '@troon/ui';
import { RichText } from './rich-text';
import type { Heading } from '@troon/ui';
import type { ComponentProps } from 'solid-js';
import type { TypeCardSkeleton } from '@troon/contentful';
import type { Entry } from 'contentful';

type Props = Entry<TypeCardSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>['fields'] & {
	level?: ComponentProps<typeof Heading>['as'];
	size?: ComponentProps<typeof Heading>['size'];
};

export function ContentCard(props: Props) {
	return (
		// @ts-expect-error
		<Card
			title={props.title}
			img={props.logo!.fields.file!.url}
			imgAlt={props.logo!.fields.description}
			banner={props.banner?.fields.file?.url}
			bannerAlt={props.banner?.fields.description}
			level={props.level}
			size={props.size}
			url={props.url}
			linkText={props.linkText}
		>
			<RichText document={props.content} />
		</Card>
	);
}
